<template>
    <div id="modal-add-rubric">
        <div class="modal-dialog block">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="block-title">{{headerTitle}}</h3>
                    <div class="block-options">
                        <button type="button" class="btn-block-option close-modal" @click="closeModal">
                            ×
                        </button>
                    </div>
                </div>
                <div class="modal-staff__tab-head">
                    <div class="modal-staff__tab-name" :class="{'modal-staff__tab-name_active':currentTab === 1}" @click="currentTab = 1">
                        Общие
                    </div>
                    <div class="modal-staff__tab-name" :class="{'modal-staff__tab-name_active':currentTab === 2}" @click="currentTab = 2">Администрирование</div>
                    <div class="modal-staff__tab-name" :class="{'modal-staff__tab-name_active':currentTab === 3}" @click="currentTab = 3">Доступ</div>
                </div>
                <ValidationObserver tag="form" class="js-without-double-block" @submit.prevent="onSubmit" ref="observer" method="post">
                    <div class="block-content content modal-staff">
                            <div v-if="currentTab === 1" class="modal-staff__tab">
                                <div class="module-set__body">
                                    <ValidationProvider rules="required" vid="name" v-slot="provider">
                                        <div class="form-group row" :class="{'is-invalid': provider.errors.length > 0}">
                                            <div class="col-12">
                                                <label for="id_name">Название рубрики *</label>
                                                <input v-model="form.name" type="text" name="title" maxlength="255"
                                                       id="id_name"
                                                       class="form-control">
                                                <div id="id_name-error" class="invalid-feedback animated fadeInDown"
                                                     v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider vid="rubric" :rules="is_parent_required ? 'required' : null" v-slot="provider">
                                        <div class="form-group row" :class="{'is-invalid': provider.errors.length > 0}">
                                            <div class="col-12">
                                                <label for="id_parent">
                                                    Родительская рубрика<span v-if="is_parent_required"> *</span>
                                                </label>
                                                <v-select2 class="form-material"
                                                       id="id_parent"
                                                       v-model="form.parent"
                                                       :reduce="name => name.id"
                                                       :options="parents" label="name"
                                                       :clearable="true" :searchable="true">
                                                    <span slot="no-options">Ничего не найдено</span>
                                                </v-select2>
                                                <div id="id_parent-error" class="invalid-feedback animated fadeInDown"
                                                     v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <div class="form-group row" v-if="permissionType === 'rubric'">
                                        <div class="col-12">
                                            <label for="id_serial_number">Порядок сортировки</label>
                                            <v-select2 class="form-material"
                                                       id="id_serial_number"
                                                       v-model="form.serial_number"
                                                       :options="numbers"
                                                       :clearable="false" :searchable="true">
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="currentTab === 2" class="modal-staff__tab">
                                <div class="module-set__body">
                                    <div class="module-set__subtitle">Сотрудники, которые могут администрировать раздел</div>
                                    <div class="form-group row col-12">
                                        <label class="modal-staff__css-control css-control">
                                            <input v-model="form.access.all_users" type="checkbox" name="all_users" class="css-control-input" id="id_all_users">
                                            <span class="css-control-indicator"></span> Все сотрудники
                                        </label>
                                    </div>
                                    <ul class="module-set__list" v-if="!form.access.all_users">
                                        <li class="module-set__item" v-for="(access, index) in accessData" :key="access.uuid">
                                            <v-select2 style="width: 100%;"
                                                       :disabled="access.rubricName"
                                                       v-model="access.user_id"
                                                       :reduce="full_name => full_name.id"
                                                       :options="access.users" label="full_name"
                                                       :clearable="false" :searchable="true">
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>
                                            <button v-if="!access.rubricName"
                                                class="module-set__del-btn" type="button" @click="removeAccess(index)">
                                                <svg width="9" height="9" viewBox="0 0 9 9"><g><g><path fill="#b8b8b8" d="M.694 9.006a.707.707 0 0 1-.503-.21.719.719 0 0 1 0-1.012l3.272-3.29L.19 1.204a.719.719 0 0 1 0-1.013.709.709 0 0 1 1.007 0l3.271 3.29L7.741.191a.709.709 0 0 1 1.007 0 .719.719 0 0 1 0 1.012l-3.272 3.29 3.272 3.291a.719.719 0 0 1 0 1.012.707.707 0 0 1-1.007 0L4.47 5.506l-3.271 3.29a.707.707 0 0 1-.504.21"></path></g></g></svg>
                                            </button>
                                            <span  v-tooltip="{
                                                    content: `Имеет доступ к родительской рубрике ${access.rubricName}`,
                                                    offset: 7
                                                }"
                                                class="modal-info-btn" style="top: 0;" v-show="access.rubricName" >(i)</span>
                                        </li>
                                    </ul>
                                    <button v-if="!form.access.all_users && users.length > 1 && accessData.length < users.length" @click="addUser"
                                        type="button" class="module-set__add-btn" style="width: 100%;">Добавить</button>
                                </div>
                            </div>
                            <div v-if="currentTab === 3" class="modal-staff__tab">
                                <div class="module-set__body">
                                    <not-modal-permission-update
                                        v-if="rubric_id"
                                        :checkbox="this.form.parent ? 'Как у родительской рубрики' : 'Для всей компании'"
                                        title="Настройка доступа к рубрике"
                                        text="Выберите отделы, должности или сотрудников, которым необходим доступ к данной рубрике"
                                        @change="changePermission"
                                        :rubricID="this.form.parent"
                                        :permissionType="permissionType"
                                        :permissionObjectID="rubric_id"
                                        :isMainRubric="isMainRubric"
                                        :dataCache="this.permissionDataCache"
                                    ></not-modal-permission-update>
                                    <not-modal-permission-create
                                        v-else
                                        :checkbox="this.form.parent? 'Как у родительской рубрики' : 'Для всей компании'"
                                        title="Настройка доступа к рубрике"
                                        text="Выберите отделы, должности или сотрудников, которым необходим доступ к данной рубрике"
                                        @change="changePermission"
                                        :permissionType="'rubric'"
                                        :rubricID="this.form.parent"
                                        :dataCache="this.permissionDataCache"
                                    ></not-modal-permission-create>
                                </div>
                            </div>
                        <div class="module-set__footer">
                            <button class="module-set__save-btn v-btn " type="submit">
                                Сохранить
                            </button>
                            <button class="module-set__cansel-btn v-btn v-btn--transparent" type="button" @click="closeModal">Отменить</button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import NotModalPermissionCreate from "../permission/NotModalPermissionCreate";
    import NotModalPermissionUpdate from "../permission/NotModalPermissionUpdate";
    import { mapState } from "vuex";
    import { eventBus } from "@/main";

    export default {
        name: "ChangeRubric",
        components: {
            NotModalPermissionCreate,
            NotModalPermissionUpdate
        },
        props: {
            permissionType: {
                type: String,
                default: 'rubric'
            },
            rubric_id: {
                type: Number,
                required: false
            },
            url_list: {
                type: String,
                required: true
            },
        },

        data() {
            return {
                isMainRubric: false,
                rubric: null,
                permissionDataCache: null,
                currentTab: 1,
                form: {
                    permissionData: {structure: {}, users: [], access_all: true, note:{parentRight: false}},
                    name: '',
                    parent: null,
                    serial_number: null,
                    access: {
                        users: [],
                        all_users: false
                    }
                },
                parents: [],
                numbers: [],
                users: [],
                accessData: [],
                headerTitle: this.rubric_id ? 'Редактирование рубрики':'Добавление рубрики',
            }
        },

        mounted() {
            this.isMainRubric = false;
            if (this.rubric && this.form.parent === this.rubric.parent) {
                this.isMainRubric = true;
            } else if (this.rubric && !this.form.parent && !this.rubric.parent) {
                this.isMainRubric = true;
            } else if (!this.rubric && !this.form.parent) {
                this.isMainRubric = true;
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
            ]),
            is_parent_required() {
                if (this.current_user.is_admin) {
                    return false
                } else if (this.current_user.is_editor_rubric_material && !this.current_user.is_editor_material) {
                    return true
                } else if (this.current_user.is_editor_rubric_docs && !this.current_user.is_editor_document) {
                    return true
                }
                return false
            }
        },
        watch: {
            'form.parent': {
                handler: function (val, oldVal) {
                    if (val) {
                        let params = {
                            permission_type: this.permissionType,
                            permission_object_id: val
                        };
                        session.get('/api/v1/permission/retrieve_vue/', {params}).then(response => {
                            this.form['permissionData'] = response.data;
                            this.form['permissionData'].note.parentRight = true;
                            this.permissionDataCache = null;
                        })
                    } else {
                        this.permissionData = {structure: {}, users: [], access_all: true, note: {parentRight: false}};
                        this.dataCache = null;
                    }

                    if (this.permissionType === 'rubric') {
                        let rubricId = this.form.parent ? this.form.parent : '';
                        let is_edit = this.rubric_id ? true : '';
                        session
                            .get(`${this.url_list}default_form/?rubric_id=${rubricId}&is_edit=${is_edit}`)
                            .then(response => {
                                let data = response.data;
                                this.numbers = data.numbers;

                                if (is_edit !== true) {
                                    this.form.serial_number = data.numbers[data.numbers.length - 1];
                                }
                            })
                    }
                },
                deep: true
            }
        },
        created() {
            let is_edit = this.rubric_id ? true : '';
            session
                .get(`${this.url_list}default_form/?is_edit=${is_edit}`)
                .then(response => {
                    let data = response.data;
                    this.users = data.users;
                    this.parents = data.parents;
                    this.numbers = data.numbers;
                    this.form.serial_number = data.numbers[data.numbers.length - 1];
                    if (this.rubric_id) {
                        session
                            .get(`${this.url_list}${this.rubric_id}/`)
                            .then(response => {
                                let data = response.data;
                                this.rubric = Object.assign({}, data);
                                this.form = data;
                                data.access.users.forEach(user_id => {
                                    this.accessData.push({
                                        uuid: this.$uuid.v1(),
                                        user_id: user_id,
                                        users: this.users
                                    })
                                });
                                data.parent_users.forEach(item => {
                                    this.accessData.push({
                                        uuid: this.$uuid.v1(),
                                        user_id: item['users__id'],
                                        rubricName: item['rubric__name'],
                                        users: this.users
                                    })
                                });
                                this.parents = this.parents.filter(item => item.id !== this.rubric_id);
                                if (data.parent_users.length === 0 && data.access.users.length === 0) {
                                    this.addUser()
                                }
                            })
                            .catch(() => {
                            });
                    }
                })
                .catch(() => {
                });

        },
        methods: {
            changePermission(data) {
                this.form.permissionData = data;
                this.permissionDataCache = data.permissionDataCache;
            },
            async removeAccess(index) {
                this.accessData.splice(index, 1);
            },
            async addUser() {
                if (this.accessData.length < this.users.length) {
                    this.accessData.push({
                        uuid: this.$uuid.v1(),
                        user_id: null,
                        users: this.users
                    })
                }
            },
            async onSubmit() {
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    let progress_bar = this.$Progress;
                    let config = {
                        onUploadProgress(progressEvent) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) /
                                progressEvent.total);
                            progress_bar.set(percentCompleted);
                            return percentCompleted;
                        },
                    };
                    this.$Progress.start();

                    let url = this.url_list;
                    this.form.access.users = [];
                    this.accessData.forEach(item => {
                        if (item.user_id) {
                            this.form.access.users.push(item.user_id)
                        }
                    });
                    try {
                        if (this.rubric_id) {
                            url = `${this.url_list}${this.rubric_id}/`;
                            let request = session.put(url, this.form, config);
                            let response = await request;
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Рубрика изменена',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        } else {
                            let request = session.post(url, this.form, config);
                            let response = await request;
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Рубрика добавлена',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        }
                        this.$root.$emit('updateContent', true);
                        if ('/api/v1/rubric_document/' === this.url_list) {
                            eventBus.$emit('rubric-update-document', true);
                        } else {
                            eventBus.$emit('rubric-update-knowledge-base', true);
                        }
                        this.$modal.hide('ChangeRubric');
                    } catch (err) {
                        this.errorHandler(err);
                    }
                    this.$Progress.finish();
                }
            },
            errorHandler(err) {
                if (err.response) {
                    this.$refs.observer.setErrors(err.response.data);
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        title: 'При обработке запроса произошла ошибка на сервере',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        }
                    });
                }

            },
            closeModal() {
                this.$modal.hide('ChangeRubric')
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    @import "#sass/settings";

    .v--modal-overlay[data-modal="ChangeRubric"]{
        z-index: 1000000 !important;
        .v--modal-box {
            overflow: visible !important;
        }

        .close-modal {
            top: 0px;
            right: 0px;
        }
    }
    .module-set__save-btn {
        margin-right: 16px;
    }
    .tooltip {
      z-index: 999999 !important;
    }
    #vue-sel-access-workers {
        height: 260px !important;
        .workers__list {
            height: 100% !important;
        }
    }
</style>
