<template>
    <div class="modal-dialog modal-dialog--access" v-if="isComponentLoaded">
        <div class="modal-content">
            <div class="modal-header modal-header_no-border">
                <h3 class="block-title">{{title}}</h3>
                <label class="material-toggle">
                    <input
                        v-if="rubricID"
                        class="material-toggle__input"
                        type="checkbox"
                        @change="copyParentPermission"
                        v-model="data.parentRight"
                    >
                    <input
                        v-else
                        class="material-toggle__input"
                        type="checkbox"
                        @change="emitData"
                        v-model="data.access_all"
                    >
                    <span class="material-toggle__icon"></span>
                    <span class="material-toggle__text">{{checkbox}}</span>
                </label>
            </div>
            <div class="block-content content modal-staff">
                <div class="workers__desc">
                    {{text}}
                </div>

                <div v-bind:class="{'area_lock': data.access_all || data.parentRight}" class="row">
                    <div class="col-lg-6 workers">
                        <div class="workers__col">
                            <div class="workers__head">
                                <ul class="material-header__nav nav" role="tablist">
                                    <li class="nav-item">
                                        <a
                                            id="vue-access-department-tab"
                                            data-toggle="tab"
                                            @click="data.isVisibleTab=true"
                                            v-bind:class="{active: data.isVisibleTab}"
                                            role="tab"
                                            aria-controls="material"
                                            aria-selected="true"
                                        >
                                            Общее
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            id="vue-access-workers-tab"
                                            data-toggle="tab"
                                            v-bind:class="{active: !data.isVisibleTab}"
                                            @click="data.isVisibleTab=false"
                                            role="tab"
                                            aria-controls="access"
                                            aria-selected="false"
                                        >
                                            Сотрудники
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="workers__body">
                                <div class="tab-content">
                                    <div class="tab-pane fade" id="vue-access-department"
                                         v-bind:class="{'active show': data.isVisibleTab}"
                                         role="tabpanel" aria-labelledby="material-tab">
                                        <div class="workers__search form-group">
                                            <svg width="13" height="13" viewBox="0 0 13 13"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                            </svg>
                                            <input v-model="data.searchStructure" type="text" class="form-control round"
                                                   placeholder="Поиск">
                                        </div>
                                        <div class="workers__tree">
                                            <ul class="workers__department department js-vue-department-tree">
                                                <permission-tree-structure @buildStructTree="clickStructure"
                                                                           @buildPositionTree="clickPosition"
                                                                           v-for="item in data.leftTree.structure"
                                                                           :item="item" :key="item.id"
                                                                           :leftTree="true"></permission-tree-structure>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="vue-access-workers" role="tabpanel"
                                         v-bind:class="{'active show': !data.isVisibleTab}"
                                         aria-labelledby="access-tab">
                                        <div class="workers__search form-group">
                                            <svg width="13" height="13" viewBox="0 0 13 13"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                            </svg>
                                            <input v-model="data.searchUsers" type="text" class="form-control round"
                                                   placeholder="Поиск">
                                        </div>
                                        <div class="workers__tree">
                                            <ul class="workers__department department">
                                                <li class="department__item open" v-for="(item, index) in filteredUsers"
                                                    :key="index">
                                                    <div class="department__row">
                                                        <span class="department__row_name">{{ item.full_name }}</span>
                                                        <label>
                                                            <input type="checkbox"
                                                                   class="access_departament"
                                                                   :data-id="'original-worker-' + item.id"
                                                                   :checked="item.checked"
                                                                   @click="leftTreeUsersBuild(item)">
                                                            <div class="department__row_button">
                                                                <i v-if="item.checked" class="fas fa-check"></i>
                                                                <i v-else class="fas fa-plus"></i>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 workers">
                        <form class="workers__col">
                            <div class="workers__head">
                                <ul class="material-header__nav nav" role="tablist">
                                    <li class="nav-item">
                                        <a id="vue-sel-access-department-tab"
                                           data-toggle="tab"
                                           v-bind:class="{active: data.isVisibleTab}"
                                           @click="data.isVisibleTab=true"
                                           role="tab"
                                           aria-controls="material"
                                           aria-selected="true"
                                           style=""
                                        >
                                            Общее
                                            <span class="nav-item__numb">{{ data.rightTree.structure.length }}</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="vue-sel-access-workers-tab"
                                           data-toggle="tab"
                                           v-bind:class="{active: !data.isVisibleTab}"
                                           @click="data.isVisibleTab=false"
                                           role="tab"
                                           aria-controls="access"
                                           aria-selected="false"
                                        >
                                            Сотрудники
                                            <span class="nav-item__numb">{{ data.rightTree.users.length }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="workers__body">
                                <div class="tab-content">
                                    <div class="tab-pane fade" id="vue-sel-access-department"
                                         v-bind:class="{'active show': data.isVisibleTab}"
                                         role="tabpanel" aria-labelledby="material-tab">
                                        <div class="workers__tree workers__tree--right">
                                            <ul class="workers__department department">
                                                <permission-tree-structure @buildStructTree="clickStructure"
                                                                           @buildPositionTree="clickPosition"
                                                                           v-for="item in data.rightTree.structure"
                                                                           :item="item"
                                                                           :key="item.id"></permission-tree-structure>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="vue-sel-access-workers" role="tabpanel"
                                         v-bind:class="{'active show': !data.isVisibleTab}"
                                         aria-labelledby="access-tab">
                                        <div class="workers__list">
                                            <ul class="workers__department department">
                                                <li class="department__item open"
                                                    v-for="(item, index) in data.rightTree.users" :key="index">
                                                    <div class="department__row">
                                                        <span class="department__row_name">{{ item.full_name }}</span>
                                                        <label>
                                                            <input type="checkbox" class="access_departament"
                                                                   :data-id="'worker-' + item.id"
                                                                   @click="rightTreeUsersBuild(item)"
                                                                   :checked="item.checked">
                                                            <div class="department__row_button">
                                                                <i v-if="item.checked" class="fas fa-check"></i>
                                                                <i v-else class="fas fa-plus"></i>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <loading-spinner v-else></loading-spinner>
</template>

<script>
    import session from '@/api/session';
    import PermissionTreeStructure from "./PermissionTreeStructure";
    import { basicMethods } from './basic_methods';
    import LoadingSpinner from "../LoadingSpinner";

    export default {
        name: 'NotModalPermissionUpdate',
        data() {
            return {
                isComponentLoaded: false
            }
        },
        mixins: [
            basicMethods
        ],
        components: {
            PermissionTreeStructure,
            LoadingSpinner
        },
        props: {
            dataCache: {
                required: false,
                type: Object
            }
        },
        async mounted() {
            await this.loadDefaultData();
            await this.loadLimitedData();
            await this.loadRetrieveData();
            if (this.rubricID && !this.isMainRubric && !this.data.access_all && this.data.parentRight) {
                this.copyParentPermission();
            }
            if (!this.rubricID && this.dataCache) {
                this.data = this.dataCache;
                this.data.access_all = this.dataCache.access_all;
                this.data.parentRight = this.dataCache.parentRight;
            }
            this.isComponentLoaded = true;
        },
        methods: {
            copyParentPermission() {
                basicMethods.methods.copyParentPermission.call(this);
                this.emitData()
            },
            clickStructure(item_id, checked) {
                basicMethods.methods.clickStructure.call(this, item_id, checked);
                this.emitData()
            },
            clickPosition(position_id, checked) {
                basicMethods.methods.clickPosition.call(this, position_id, checked);
                this.emitData()
            },
            leftTreeUsersBuild(item) {
                basicMethods.methods.leftTreeUsersBuild.call(this, item);
                this.emitData()
            },
            rightTreeUsersBuild(item) {
                basicMethods.methods.rightTreeUsersBuild.call(this, item);
                this.emitData()
            },
            emitData() {
                let data = this.dataPreparation();
                data.permissionDataCache = this.data;
                this.$emit('change', data);
            }
        }
    }
</script>

<style lang="scss">
    .modal {
        overflow-y: auto;
    }
    .show-permission {
        display: flex;
        overflow-y: auto !important;
        background: rgba(0, 0, 0, 0.2);
    }
    .area_lock {
        opacity: 0.5;
        pointer-events: none;
    }
    .display_flex {
        display: flex !important;
    }
    .is_invalid {
        border-color: #ef5350;
    }

    .modal-staff__tab-head {
        margin-bottom: 15px;
    }

    .modal-staff__tab-name {
        width: 33%;
        margin-right: 0;

        text-align: center;
    }

    .material-content .select2-container--default.select2-container--focus .select2-selection--multiple {
        border: 1px solid #e9e9e9 !important;
        border-radius: 2px !important;
    }

    .material-content .form-material .select2-container--default .select2-search--inline .select2-search__field {
        margin-top: 10px;
        line-height: 24px;
        height: 26px;
    }
    .material-content .form-material .select2-container--default .select2-search--inline .select2-search__field::placeholder {
        opacity: 0 !important;
        visibility: hidden !important;
    }
    .workers__search .form-control {
        padding-left: 36px;
    }

    .workers,
    .workers-selected {
        display: flex;
        flex-direction: column;
    }

    .ui-autocomplete .ui-menu-item {
        word-break: break-all;
        word-break: break-word;
        overflow-wrap: break-word;
    }

    .modal-dialog.modal-dialog--access {
        width: 100%;
        max-width: 920px;
        margin: 0;
    }

    .modal-dialog--access.remove-access {
        max-width: 400px;
    }

    .modal-dialog--access .close-modal {
        top: -12px;
        right: -46px;
        font-size: 40px;
    }

    .modal-dialog--access .workers__search {
        margin: 0 15px 10px 0;
    }

    .modal-dialog--access .workers__col{
        height: 100%;

        border: 2px solid #E8E8E8;
    }

    .modal-dialog--access .workers__head {
        padding: 0 15px;
        border-bottom: 2px solid #E8E8E8;
    }

    .modal-dialog--access .workers__body {
        padding: 15px 0 0 15px;
    }

    .modal-dialog--access .workers__head .material-header__nav {
        justify-content: space-between;
    }

    .modal-dialog--access .workers__head .material-header__nav .nav-item a {
        padding: 15px;
        letter-spacing: 0.5px;
    }

    .modal-dialog--access .workers__head .material-header__nav .nav-item a.active:before {
        top: -2px;
        bottom: auto;
    }

    .modal-dialog--access .workers__head .material-header__nav .nav-item:not(:last-child) {
        margin-right: 0;
    }

    .modal-dialog--access .workers__head .nav-item__numb {
        font-size: 0.75rem;
        font-weight: 400;
        color: #AAAAAA;
    }

    .modal-dialog--access .department__item,
    .modal-dialog--access .selected-department__item {
        padding: 0;
    }

    .modal-dialog--access .department__item > .department__row .department__icon_top {
        display: none;
    }

    .modal-dialog--access .department__item.open > .department__row .department__icon_bottom {
        display: none;
    }

    .modal-dialog--access .department__item.open > .department__row .department__icon_top {
        display: inline;
    }

    .modal-dialog--access .workers__department .workers__department {
        display: none;
    }

    .modal-dialog--access .workers__tree .workers__department .workers__department {
        padding-left: 10px;
        border-left: 1px solid #D2D2D2;
        margin-left: 10px;
    }

    .modal-dialog--access .workers__department .workers__department .department__row_name {
        font-weight: 400;
    }

    .modal-dialog--access .department__item.open > .workers__department {
        display: block;
    }

    .modal-dialog--access {
        border-radius: 10px;
    }

    .modal-dialog--access .modal-content {
        padding: 0;
    }

    .remove-access .workers {
        padding: 0 15px;
    }

    .modal-dialog--access .workers__search .form-control {
        padding-left: 30px;
        border: none;
        border-radius: 2px;
        font-size: 0.875rem;
        color: #9B9B9B;
        background: #F3F3F3;
    }

    .modal-dialog--access .workers__search .form-control::placeholder {
        color: #9B9B9B;
    }

    .modal-dialog--access .workers__department {
        border: none;
    }

    .modal-dialog--access .modal-header {
        padding: 0;
    }

    .modal-dialog--access .block-title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 22px;
    }

    .modal-dialog--access .workers__search svg {
        position: absolute;
        top: 14px;
        left: 12px;
        fill: #BDBDBD;
    }
    .modal-dialog--access .workers__list,
    .modal-dialog--access .workers__tree {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 15px;
    }

    .modal-dialog--access .workers__tree--right {
        height: auto;
    }

    .modal-dialog--access .block-content {
        padding: 25px 0 0;
    }

    .modal-dialog--access .department__list,
    .modal-dialog--access .selected-department__list {
        padding: 0;
        margin-top: 0;
    }

    .modal-dialog--access .department__list_item:hover,
    .modal-dialog--access .selected-department__list_item:hover {
        background: #F8F8F8;
    }

    .modal-dialog--access .department__row_arrow i,
    .modal-dialog--access .selected-department__row_arrow i {
        margin-top: 4px;
    }

    .modal-dialog--access .department__row_name,
    .modal-dialog--access .selected-department__row_name {
        font-size: 0.875rem;
        line-height: 18px;
        font-weight: 600;
    }

    .modal-dialog--access .department__row_button {
        display: block;
    }

    .modal-dialog--access .department__row,
    .modal-dialog--access .selected-department__row {
        padding: 9px 0 9px 5px;
    }

    .modal-dialog--access .department__row label,
    .modal-dialog--access .selected-department__row label {
        margin: 0;
    }

    .modal-dialog--access .department__row_arrow,
    .modal-dialog--access .selected-department__row_arrow {
        margin-right: 6px;
        margin-top: -2px;
        width: 10px;
    }

    .modal-dialog--access .access_departament {
        position: absolute;
        left: -9999px;
    }

    .modal-dialog--access .access_departament:checked + .department__row_button {
        background: #FFB71C;
    }

    .modal-dialog--access .access_departament:checked + .department__row_button i {
        color: #ffffff;
    }

    .modal-dialog--access .access_departament:checked + .department__row_button .icon-add {
        display: none;
    }

    .modal-dialog--access .access_departament:checked + .department__row_button .icon-check {
        display: block;
    }

    .modal-dialog--access .worker label {
        font-size: 0.875rem;
        line-height: 18px;
        font-weight: 400;
        padding: 10px 21px 10px 56px;
    }

    .modal-dialog--access .selected-department__list_item {
        font-size: 0.625rem;
        padding: 8px 21px 8px 56px;
    }

    .modal-dialog--access .selected-department__row_buttons .marker,
    .modal-dialog--access .selected-department__item_buttons .marker {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        font-size: 0.625rem;
        line-height: 10px;
        text-align: center;
        display: block;
        color: #000000;
        padding: 2px;
    }

    .modal-dialog--access .selected-department__row_buttons .marker.disabled,
    .modal-dialog--access .selected-department__item_buttons .marker.disabled {
        border-color: #EDEDED;
    }

    .modal-dialog--access .selected-department__item_buttons .attestation-checkbox-label {
        margin: 0;
    }

    .modal-dialog--access .selected-department__row_buttons i {
        font-size: 0.625rem;
    }

    .modal-dialog--access .worker input:checked ~ .worker__button {
        background: #FFB71C;
    }

    .modal-dialog--access .worker input:checked ~ .worker__button .fa-check {
        color: #ffffff;
    }

    .modal-dialog--access .workers-selected__title {
        padding: 17px 21px 12px;
        font-size: 0.625rem;
        line-height: 13px;
        letter-spacing: 0.2em;
        color: #C1C1C1;
    }

    .modal-dialog--access .workers-selected__desc-block .workers-selected__desc-block_first {
        padding-right: 32px;
    }

    .modal-dialog--access .workers-selected__desc-block_marker {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        padding: 2px 4px;
        line-height: 1;
        font-size: 0.625rem;
        position: absolute;
        top: -4px;
        left: 58px;
        margin: 0;
    }

    .modal-dialog--access .module-set__footer {
        padding: 40px 0 0;
    }

    .modal-dialog--access .module-set__footer .module-set__save-btn,
    .modal-dialog--access .module-set__footer .module-set__cansel-btn {
        font-size: 1rem;
        line-height: 12px;
        font-weight: 600;
    }

    .modal-dialog--access .module-set__footer .module-set__save-btn {
        margin: 0 16px 0 0;
        color: #000;
        padding: 15px 31px;
    }

    .modal-dialog--access .module-set__footer .module-set__save-btn:hover {
        background: #FFC956;
    }

    .modal-dialog--access .module-set__footer .module-set__cansel-btn {
        color: #9D9D9E;
        background: #ffffff;
        padding: 14px 31px;
    }

    .modal-dialog--access .module-set__footer .module-set__cansel-btn:hover {
        border-color: #959595;
    }

    .modal-dialog.modal-dialog--round {
        border-radius: 10px;
        max-width: 352px;
        margin-top: 260px;
    }

    .modal-dialog--round .modal-content {
        border-radius: 10px;
    }

    .modal-dialog--round .modal-header {
        padding: 23px 35px 0 31px;
    }

    .modal-dialog--round .modal-header .block-title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 27px;
    }

    .modal-dialog--round .module-set__footer {
        padding: 22px 35px 35px 31px;
        justify-content: flex-start;
    }

    .modal-dialog--round .module-set__footer .module-set__save-btn,
    .modal-dialog--round .module-set__footer .module-set__remove-btn,
    .modal-dialog--round .module-set__footer .module-set__cansel-btn {
        font-size: 1rem;
        line-height: 12px;
        font-weight: 400;
    }

    .modal-dialog--round .module-set__footer .module-set__save-btn,
    .modal-dialog--round .module-set__footer .module-set__remove-btn {
        margin: 0 16px 0 0;
        color: #ffffff;
        padding: 15px 31px;
    }

    .modal-dialog--round .module-set__footer .module-set__save-btn:hover {
        background: #FFC956;
    }

    .modal-dialog--round .module-set__footer .module-set__remove-btn {
        background: #EB5757;
    }

    .modal-dialog--round .module-set__footer .module-set__remove-btn:hover {
        background: #FF7676;
    }

    .modal-dialog--round .module-set__footer .module-set__cansel-btn {
        border: 1px solid #D5D5D5;
        color: #000000;
        background: #ffffff;
        padding: 14px 31px;
    }

    .modal-dialog--round .module-set__footer .module-set__cansel-btn:hover {
        border-color: #959595;
    }

    @media screen and (max-width: 992px) {
        .modal-dialog--access.remove-access.block,
        .modal-dialog--round.block,
        .modal-dialog--access.block {
            margin: auto;
        }
    }

    @media screen and (max-width: 800px) {
        .modal-dialog--access {
            width: calc(100% - 20px);
            margin: 10px 10px 24px;
        }

        .modal-dialog--access .workers__desc,
        .modal-dialog--access .workers-selected__desc-block {
            width: 90%;
        }
    }

    @media (min-width: 721px) and (max-width: 991px) {
        .modal-dialog--access .workers-selected {
            margin-top: 0;
        }

        .modal-dialog--access .block-content .row .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @media (min-width: 661px) and (max-width: 720px) {
        .modal-dialog--access .workers-selected {
            margin-top: 0;
        }

        .modal-dialog--access .block-content .row .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @media screen and (max-width: 720px) {
        #modal-add-access {
            padding: 0 !important;
            margin-top: 68px;
            border-top: 1px solid #F0F2F5;
        }

        .modal-dialog--access.remove-access .modal-content {
            border-radius: 10px;
        }

        .modal-dialog--access:not(.remove-access) {
            width: 100%;
            margin: 0;
            padding: 0;
            height: calc(100vh - 69px);
        }

        .modal-dialog--access:not(.remove-access) .modal-content {
            padding: 0 10px;
        }

        .modal-dialog--access:not(.remove-access) .modal-header {
            padding: 18px 0 0;
            border: none;
        }

        .modal-dialog--access:not(.remove-access) .block-title {
            font-size: 1.25rem;
            font-weight: 600;
        }

        .modal-dialog--access:not(.remove-access) .close-modal {
            top: 10px;
            right: 0;
        }

        .modal-dialog--access:not(.remove-access) .block-content {
            padding: 11px 0 0;
        }

        .modal-dialog--access:not(.remove-access) .workers__search {
            margin-top: 16px;
        }

        .modal-dialog--access:not(.remove-access) .workers__desc,
        .modal-dialog--access:not(.remove-access) .workers-selected__desc-block {
            width: 94%;
        }

        .modal-dialog--access:not(.remove-access) .department__row,
        .modal-dialog--access:not(.remove-access) .selected-department__row {
            padding: 9px 16px;
        }

        .modal-dialog--access:not(.remove-access) .department__row_arrow,
        .modal-dialog--access:not(.remove-access) .selected-department__row_arrow {
            margin-right: 10px;
        }

        .modal-dialog--access:not(.remove-access) .workers__department {
            padding-top: 10px;
        }

        .modal-dialog--access:not(.remove-access) .worker label,
        .modal-dialog--access:not(.remove-access) .selected-department__list_item {
            padding: 10px 16px 10px 50px;
        }

        .modal-dialog--access:not(.remove-access) .workers-selected__title {
            margin-top: 14px;
        }

        .modal-dialog--access:not(.remove-access) .workers-selected {
            margin-top: 26px;
        }

        .modal-dialog--access:not(.remove-access) .module-set__footer {
            padding: 12px 0;
            flex-direction: column;
        }

        .modal-dialog--access:not(.remove-access) .module-set__footer .module-set__save-btn {
            margin: 0;
            width: 100%;
        }

        .modal-dialog--access:not(.remove-access) .module-set__footer .module-set__cansel-btn {
            margin-top: 10px;
            width: 100%;
        }

        .modal-backdrop.hide {
            opacity: 0;
        }
    }

    @media screen and (max-width: 460px) {
        #modal-remove-access {
            padding: 0 !important;
            margin-top: 68px;
            border-top: 1px solid #F0F2F5;
        }

        #modal-add-favorite {
            padding: 0 !important;
        }

        .modal-dialog--access {
            border-radius: 0;
            height: calc(100vh - 69px);
        }

        .modal-dialog--access .modal-content {
            border-radius: 0;
        }

        .modal-dialog--access.remove-access {
            width: 100%;
            margin: 0;
            padding: 0;
            max-width: initial;
        }

        .modal-dialog--access .modal-content {
            padding: 0 10px;
        }

        .modal-dialog--access .modal-header {
            padding: 18px 0 0;
            border: none;
        }

        .modal-dialog--access .block-title {
            font-size: 1.25rem;
            font-weight: 600;
        }

        .modal-dialog--access .close-modal {
            top: 10px;
            right: 0;
        }

        .modal-dialog--access .block-content {
            padding: 11px 0 0;
        }

        .modal-dialog--access .workers__search {
            margin-top: 16px;
        }

        .modal-dialog--access .workers__desc,
        .modal-dialog--access .workers-selected__desc-block {
            width: 94%;
        }

        .modal-dialog--access .department__row,
        .modal-dialog--access .selected-department__row {
            padding: 9px 16px;
        }

        .modal-dialog--access .department__row_arrow,
        .modal-dialog--access .selected-department__row_arrow {
            margin-right: 10px;
        }

        .modal-dialog--access .workers__department {
            padding-top: 10px;
        }

        .modal-dialog--access .worker label,
        .modal-dialog--access .selected-department__list_item {
            padding: 10px 16px 10px 50px;
        }

        .modal-dialog--access .workers-selected__title {
            margin-top: 14px;
        }

        .modal-dialog--access .workers-selected {
            margin-top: 26px;
        }

        .modal-dialog--access .module-set__footer {
            padding: 12px 0;
            flex-direction: column;
        }

        .modal-dialog--access .module-set__footer .module-set__save-btn {
            margin: 0;
            width: 100%;
        }

        .modal-dialog--access .module-set__footer .module-set__cansel-btn {
            margin-top: 10px;
            width: 100%;
        }

        .modal-dialog--round {
            margin: auto;
        }
    }

    @media screen and (max-width: 390px) {
        .modal-dialog--round {
            width: calc(100% - 26px);
            margin: auto 13px auto;
        }

        .modal-dialog--round .module-set__footer {
            flex-direction: column;
        }

        .modal-dialog--round .module-set__footer .module-set__save-btn {
            margin: 0;
            width: 100%;
        }

        .modal-dialog--round .module-set__footer .module-set__cansel-btn {
            margin-top: 6px;
            width: 100%;
        }
    }
</style>
