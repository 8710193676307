import { render, staticRenderFns } from "./NotModalPermissionCreate.vue?vue&type=template&id=96398518&"
import script from "./NotModalPermissionCreate.vue?vue&type=script&lang=js&"
export * from "./NotModalPermissionCreate.vue?vue&type=script&lang=js&"
import style0 from "./NotModalPermissionCreate.vue?vue&type=style&index=0&id=96398518&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports